/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/owncloud-logo.png",
    alt: "",
    title: "ownCloud "
  }), "Vía ", React.createElement(_components.a, {
    href: "http://www.kdeblog.com/",
    title: "KDE Blog"
  }, "KDE Blog"), " descubro las ", React.createElement(_components.a, {
    href: "http://www.kdeblog.com/lanzado-owncloud-3.html",
    title: "Lanzado OwnCloud 3"
  }, "novedades de OwnCloud 3"), ", ese servidor libre para crear tu propi", React.createElement(_components.img, {
    src: "images/akregator%281%29.png",
    alt: "http://www.thinkdigit.com/FCKeditor/uploads/akregator(1).png"
  }), "a nube, aunque me sorprende gratamente con todos esos visualizadores y editores de documentos con los que podremos acceder a nuestros archivos sin tener que descargarlos, me impresiona más una funcionalidad que plantean añadir en versiones futuras, la posibilidad de tener nuestro lector ", React.createElement(_components.a, {
    href: "http://www.kdeblog.com/el-retorno-de-akregator.html"
  }, "RSS en OwnCloud"), " sincronizado con ", React.createElement(_components.a, {
    href: "http://kde.org/applications/internet/akregator/",
    title: "Akregator"
  }, "Akregator"), "."), "\n", React.createElement(_components.p, null, "Además le veo muchas posibilidades si lo unimos a la gestión de enlaces que ya teníamos de versiones anteriores, lo unico que me faltaría para completar la combinación perfecta es poder usar los marcadores en Firefox mediante ", React.createElement(_components.a, {
    href: "http://support.mozilla.org/es/kb/Qu%C3%A9%20es%20Firefox%20Sync",
    title: "Firefox Sync"
  }, "Sync"), ". Supongo que todo ", React.createElement(_components.a, {
    href: "http://forum.owncloud.org/viewtopic.php?f=4&t=118"
  }, "será cuestión de tiempo"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
